























import { computed, defineComponent } from '@vue/composition-api';
import { useGetters } from 'vuex-composition-helpers';

import { isBefore, parseISO, subYears } from 'date-fns';

import { Report } from '@/models/report.model';
import { GET_REPORTS } from '@/store/report.actions';
import ReportList from '@/components/ReportList.vue';


export default defineComponent({
  name: 'ArchiveReportListView',
  components: {
    ReportList
  },
  setup: () => {
    const getters = useGetters([GET_REPORTS]);
    const reports = getters[GET_REPORTS];

    const today = new Date();
    const filteredReports = computed(() => {
      return (reports.value as Report[]).filter(op => {
        const dateStr = op.zeitstempel;
        if (!dateStr || dateStr.length === 0) {
          return false;
        }

        const earliestDate = subYears(today, 10);
        const date = parseISO(dateStr);

        return isBefore(date, earliestDate);
      });
    });

    return {
      filteredReports
    };
  }
});
